<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;"
	 v-loading="laoding">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->
		<!-- 面包屑END -->
		<div style="height: 111px;background: #FFFFFF;width: 100%;margin-top: 10px;" class="border-F2F2F2">
			<div style="padding: 15px 140px;">
				<el-steps :active="active" :align-center="true" finish-status="success">
					<el-step title="商品详情"></el-step>
					<el-step title="填写订单"></el-step>
					<el-step title="支付订单"></el-step>
					<el-step title="支付结果"></el-step>
				</el-steps>
			</div>
		</div>
		<!-- <div class="" @click="toEdit">
				编辑订单
			</div> -->
		<div class="">
			<div v-for="(item, index) in orderList" :key="index">
				<div class="font-size16 font-weight700 margin-b-10 margin-t-10 color-theme">
					订单{{index+1}}
				</div>
				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div>
						<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 flex-row-space-between-center">收货人信息</div>
						<div v-if="JSON.stringify(item.address) != '{}'">
							<div class="price-box active border-color-theme flex-row-space-between-center pointer box-sizing" style="background: #f8f9fe;"
							 @mouseover="mouse = -99" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center">
									<div class=" flex-column-align-center margin-l-10 color-theme ">
										<div>{{ item.address.fLinkman }}</div>
										<div>{{ item.address.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 color-theme">工程名称：{{ item.fProjectName }}</div>
									<div class="margin-l-15 color-theme">收货地址：{{ item.address.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10 " v-show="mouse == -99">
									<i class="el-icon-edit font-color-666 font-size18 pointer" style="padding-right: 20px;" @click="EditAddress(item.address)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18 pointer" @click="DeleteAddressChecked"></i> -->
								</div>
							</div>
							<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
							<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme" v-for="(addressitem, addressindex) in item.noDefaultAddress"
							 :key="addressindex" style="background: #f8f9fe;" v-if="addressStatus && addressIndex == index" @mouseover="mouse = addressindex"
							 @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center font-color-999" @click="addressChoose(addressitem, index)">
									<div class=" flex-column-align-center margin-l-10 font-color-999">
										<div class="">{{ addressitem.fLinkman }}</div>
										<div class="">{{ addressitem.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 font-color-999">工程名称：{{ addressitem.fProjectName }}</div>
									<div class="margin-l-15 font-color-999">收货地址：{{ addressitem.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10" v-show="mouse == addressindex">
									<i class="el-icon-edit  font-color-666 font-size18" style="padding-right: 20px;" @click="EditAddress(addressitem)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18" @click="DeleteAddress(addressitem, addressindex)"></i> -->
								</div>
							</div>
							<div class="margin-lr-15 flex-row-align-center">
								<div class="pointer margin-r-10" @click="noDefaultAddressShow(index)">
									{{ addressStatus && addressIndex == index ? '收起地址' : '展开地址' }}
									<i class=" " :class="addressStatus && addressIndex == index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								</div>
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
						<div v-else>
							<div class="price-box active flex-row-space-between-center box-sizing padding-lr-10 font-color-999" style="background: #f8f9fe;">
								暂无可用收货地址
							</div>
							<div class="padding-lr-13 margin-t-15 margin-b-15">
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
					</div>
					<!-- 收货人信息结束 -->
          <!-- 定向送达活动 -->
          <!-- <div class="express" v-show="isShowExpress" >
            <div class="btn" @click="showActivity(index)">详情 ></div>
            <div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15">定向送达活动</div>
            <el-table
              v-loading="loadingactivity"
              v-show="tableData[index].length > 0"
              :header-cell-style="{background:'#F8F9FE'}"
              class="margin-b-10 margin-t-10 margin-lr-15"
              :data="tableData[index]"
              size="mini"
              border
              style="width: 1070px">
              <el-table-column
                align="left"
                label="送达目的地"
                fixed
                width="200">
                <template slot-scope="scope">
                  <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="fDeliveryNames"
                label="提货方式"
                width="150">
              </el-table-column>
              <el-table-column
                align="center"
                label="运输时长(分钟)"
                width="250">
                <template slot-scope="scope">
                  <span>{{scope.row.fMaxMins}}</span>
                  <hr>
                  <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="活动时间">
                <template slot-scope="scope">
                  <span>{{scope.row.activityTime}}</span>
                  <hr>
                  <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="integral"
                label="赠送积分"
                width="120">
              </el-table-column>
            </el-table>
          </div> -->


					<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">商品信息</div>
					<div class=" margin-lr-15">
						<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #f8f9fe;">
							<p class="width756" style="text-align: center;">商品信息</p>
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
						<div class="wrap">
							<div class=" ">
								<div class="">
									<div class="flex-row-space-between-center" style="height: 123px;">
										<div class="flex-row-start-start">
											<div class="flex-row-space-between-center" style="margin-left: 15px;">
												<div class="box-shadow-all imageRadius" style="">
													<el-image class="imageRadius" :src="item.fPicturePath"></el-image>
												</div>
											</div>
											<div style="margin-left: 19px;">

												<div class="">
													<div class="font-size12 font-color-fff radius2 margin-r-5" style="display: inline-block;background-color:#40C6A9;padding: 0 3px;">企</div>
													<div class="tag-box font-size12 font-color-fff radius2 margin-r-5" style="display: inline-block;" v-if="item.fShopTypeID == 1">{{ item.fShopTypeName }}</div>
													{{ item.fGoodsFullName }}
												</div>
												<div class="flex-row" style="margin-top: 8px;">
													<div class="tag-box-red  font-size12 " v-if="item.text">{{ item.text }}</div>
													<div class="tag-box-blue color-theme border-theme font-size12" v-if="item.dei">{{ item.dei }}</div>
												</div>
												<!-- <div>工程：达坂城第一工程</div> -->
											</div>
										</div>
										<div class=" ">
											<div class="flex-row">
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<el-input-number size="small" v-model="item.fPlanAmount" :min="item.fMinSaleAmount" :max="99999" :precision="item.fPrecision"
													 :step="item.fAdjustmentRange" @change="valChange(item,index)" @blur="valBlur(item,index)" @focus="getInputFocus($event)"></el-input-number>
												</div>
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<p class="font-weight700">￥{{item.fGoodsPrice | NumFormat}}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="border-top-F2F2F2">
									<!--资金类型-->
									<div class="padding-lr-15" style="padding-top: 6px;padding-bottom: 7px;">
										<el-row >
											<el-col :span="12">
												<div class="flex-row-align-center" style="min-width: 380px !important;  align-items: center;height:30px">
													资金类型：
													  <el-radio-group v-model="item.fCapTypeID" @change="capTypeIDClick(item.fCapTypeID,index)">
														  <el-radio :label="item2.fCapTypeID"  v-for="(item2, index2) of item.fCapList" >
															  {{item2.fCapType}}
														  </el-radio>
													  </el-radio-group>
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="margin-top: 6px;margin-bottom: 7px;">
										<el-row :gutter="24">
											<el-col :span="9">
												<div class="flex-row-align-center" style="min-width:380px;align-items: center;height:30px">
													<div style="display: flex;align-items: center;">
														<span>配送方式：</span>  
														<el-tooltip placement="right">
															<div slot="content">
																1、自提：由卖家在每月扎账后为您开具已完成出厂金额的货款发票。<br/>
																2、自营配送：由聚材在每月扎帐日后为您开具已完成出厂且配送的相应金额的货款发票。<br/>
																3、店铺配送：由第三方店铺为您开具相应已完成订单金额的货款发票。<br/>
																4、承运商代送：由聚材根据完成的出厂量按月为您开具相应的货款发票、提供物流服务的服务商开具运输发票。<br/>
																5、详细规则请参见《平台交易规则》、《物流服务协议》、《自营配送协议》。
															</div>
															<div class="form-label-mark flex-row-center-center margin-l-3">?</div>
														</el-tooltip>
													</div>
													<div style="display: flex;align-items: center;">
														<el-radio-group v-model="item.fDeliveryID" @change="deliveryMethodClick(item.fDeliveryID,index)">
															<el-radio :label="item2.fDeliveryID" :disabled='item.fNegotiateStatus == 2? true:false'  v-for="(item2, index2) of item.deliveryDtos" >
																	{{item2.fDelivery}}
															</el-radio>
														</el-radio-group>
													</div>
												</div>
											</el-col>
											<el-col :span="9" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center">
													选承运商：
													<el-select  style="margin-left: 27px;" v-model="item.carrierData" size="mini" @change="carrierClick(item.carrierData,index)">
														<el-option v-for="(item4, index4) in item.carrierDtos" :key="index4" :label="item4.fCustomerName" :value="item4.fCarrierID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="6" v-if="item.fDeliveryID == 1">
												<div  class="flex-row-align-center font-color-FF0000 font-weight700" style="float:right;color:black">
													运费单价:￥<span v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span v-else>{{item.fFreightPriceTax}}</span>  
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" >
										<el-row :gutter="24">
											<el-col :span="7">
												<div class="flex-row-align-center">
													运输方式：
													<el-select v-model="item.fBusinessTypeID" size="mini" @change="BusinessTypeChoose(index)">
														<el-option v-for="(item7, index7) in item.BusinessType" :key="index7" :label="item7.fBuTypeName" :value="item7.fBusinessTypeID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="8" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center">
													开具运输发票：
													<el-select v-model="item.fIsNeedFInvoiceStatus" size="mini" @change="transportationInvoiceClick(item.fIsNeedFInvoiceStatus,index)">
														<el-option v-for="(item7, index7) in item.transportationInvoiceDtos" :key="index7" :label="item7.title"
														 :value="item7.id"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="5" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center font-color-999 font-size12" style="height: 30px;">
													<span class="iconfont icon-tishi5 font-color-FFAA00 margin-r-5"></span>
													运费单价,不含装卸等其他费用
												</div>
											</el-col>
										</el-row>
									</div>
									<!-- <div class="margin-lr-15 margin-t-10">
										<el-row :gutter="24">
											
										</el-row>
									</div> -->
								</div>
								<div style="padding-top: 8px;" class="divcss5-3"></div>
								<div style="height: 84px;">
									<div class="flex-column-start-end font-size12 " style="margin-right: 15px;line-height: 22px;">
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											货款金额：
											<div>¥{{item.fGoodsPrice * item.fPlanAmount | NumFormat}}</div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											运费金额：
											<div>+ ¥<span v-if='item.fDeliveryID == 1 ? true:false'>{{item.freightAmount | NumFormat}}</span><span v-else>{{item.freightAmount * 0 | NumFormat}}</span> </div>
										</div>
										<div class="flex-row-space-between-center" style="width: 180px;">
											<div>小计：</div>
											<div class="font-color-FF0000 font-weight700">¥{{ getOrderPrice(item) | NumFormat }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 发票信息 -->
			<div class="flex-row">
				<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-r-10">发票信息</div>
				<div class="flex-row-align-center border-box">
					开具货款发票：
					<!-- <el-select v-model="fIsNeedInvoiceStatus" size="mini" @change="paymentForGoodsInvoiceClick(fIsNeedInvoiceStatus)">
						<el-option v-for="(item, index) in fIsNeedInvoiceStatusList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select> -->
					<el-radio v-model="fIsNeedInvoiceStatus" :label="1">需要</el-radio>
					<el-radio v-model="fIsNeedInvoiceStatus" :label="0">不需要</el-radio>
				</div>
			</div>
			<div class="border-F2F2F2">
				<div class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;"
				 v-if="invoiceInformation.feMail!=null">
					<div class="flex-row-align-center">
						<i class="el-icon-s-ticket font-size18"></i>
						<p class="font-size14" style="padding-left: 6px;">发票信息</p>
						<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
					</div>
					<i class="el-icon-arrow-up font-size18 pointer" :class="invoiceInformation.invoiceInformationTipsShow?'el-icon-arrow-up':'el-icon-arrow-down'"
					 @click="invoiceInformationTipsShowClick"></i>
				</div>
				<div v-else class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
					<div class="flex-row-align-center"><a class="font-size14 pointer hover" style="padding-left: 6px;" @click="addInvoice('Add')">+维护发票收件地址</a></div>
				</div>

				<div class="font-size12 font-color-666 bg-fff" style="padding: 10px 21px;" v-if="invoiceInformation.invoiceInformationTipsShow">
					<div class="margin-b-5">
						<div class="flex-row line-height22">
							<div>发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row line-height22">
							<div>发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row" :class="getUserInfo.fCommerceTypeID=='1'?'margin-b-10 padding-b-10 border-bottom-F2F2F2':'line-height22'">
							<div>{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row line-height22">
								<div>住所：</div>
								<div>{{invoiceInformation.fAddress}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row margin-b-10 padding-b-10 border-bottom-F2F2F2">
								<div>银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div>
								<div class="flex-row">
									<div>邮寄地址：</div>
									<div>{{invoiceInformation.fFullPathAddress}}</div>
								</div>
								<div class="flex-row line-height22">
									<div>收件人：</div>
									<div>{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
								</div>
								<div class="flex-row">
									<div>邮箱：</div>
									<div>{{invoiceInformation.feMail}}</div>
								</div>
							</div>
							<div class="flex-row-align-center pointer hover color-theme" @click="addInvoice('Edit')">
								修改发票收件地址
							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- 发票信息结束 -->
			<div class="flex-row-right bottomBox font-size16" style="line-height: 30px;margin-top: 17px;margin-bottom: 50px;">
				<!-- <div class="flex-row-space-between-center font-size14 font-color-666"> -->
				<div class="flex-row-center-center" style="margin-right: 30px;">
					<div class="flex-row-align-center">
						<div class="flex-row">
							<p class="font-color-FF0000 ">{{ orderList.length }}</p>
							<p class="font-color-999">件商品</p>
							<p class="font-color-000">，实付金额：</p>
							<p class="font-color-FF0000 font-weight700">¥{{ getOrderTotal() | NumFormat }}</p>
						</div>

						<!-- <router-link to="/paymentOrder"> -->
						<!-- <router-link to="/shoppingCart/fillOrder/paymentOrder"> -->
						<el-button v-if='isPrice'    style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" :loading="createLoading"
						 @click="asktalkAboutPrice">运费议价</el-button>
						<button style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" @click="createOrder">提交订单</button>
						<!-- </router-link> -->
					</div>
				</div>
				<!-- </div> -->
			</div>
		</div>
		<!-- 收货地址 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="pageTitle + '收货地址'" :visible.sync="addVisible" @close="handleClose"
		 width="600px" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="130px" label-position="right">
					<el-form-item label="收货人" prop="fLinkman">
						<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="fLinkPhone">
						<el-input v-model="addressForm.fLinkPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="行政区域" class="areaBox" prop="area">
						<el-col :span="24">
							<selectArea v-model="addressForm.area"></selectArea>
						</el-col>
						<!-- <div class="mapBtn pointer font-size14 font-color-666" @click.once="getMyAddress">
							<span class="iconfont icon-dingwei margin-r-6"></span>获取当前地址
						</div> -->
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<!-- <el-form-item label="地址类型" prop="fAddressTypeID">
						<el-select v-model="addressForm.fAddressTypeID" clearable placeholder="请选择地址类型">
							<el-option v-for="item in addressList" :key="item.fAddressTypeID" :label="item.fAddressType" :value="item.fAddressTypeID">
							</el-option>
						</el-select>
					</el-form-item> -->
					<!-- <el-form-item label="电子邮箱" prop="feMail">
						<el-input v-model="addressForm.feMail" placeholder='请输入电子邮箱' />
					</el-form-item> -->
					<el-form-item label="工程名称" prop="fProjectName">
						<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
					</el-form-item>
					<el-form-item label="设置默认地址" prop="addressType">
						<el-switch v-model="addressForm.fIsDefault"></el-switch>
					</el-form-item>
					<el-form-item>
						<div id="container" style="height: 200px;" v-show="mapShow"></div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 维护发票信息 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="invoiceType=='Add'?'维护':'编辑'+'发票收件地址'" :visible.sync="addInvoiceStatus"
		 width="600px" :before-close="addInvoiceClose" :close-on-click-modal="false" class="invoice">
			<div class=" " style="">
				<div class="font-size12 font-color-666 bg-F2F4FD" style="margin-bottom: 5px;">
					<div class="invoice-box" style="line-height: 22px;padding: 10px 15px;">
						<div class="font-size14 font-weight700 font-color-000">{{getUserInfo.fCommerceTypeID=='1'?'个人：':'企业：'}}发票信息</div>
						<div class="flex-row">
							<div style="width: 70px;">发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row">
								<div style="width: 80px;">住所：</div>{{invoiceInformation.fAddress}}
							</div>
							<div class="flex-row">
								<div style="width: 70px;">公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<!-- <div class="flex-row">
							<div style="width: 70px;">邮寄地址：</div>
							<div>新疆乌鲁木齐市新市区经济开发区卫星路 499号秦郡大厦B座501室</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">收件人：</div>
							<div>王俊凯 16612345789</div>
						</div> -->
					</div>
				</div>
				<el-form ref="addInvoiceForm" :rules="rules" :model="addInvoiceForm" label-width="140px" label-position="right">
					<el-form-item label="收件邮箱" prop="feMail">
						<el-input v-model="addInvoiceForm.feMail" />
					</el-form-item>
					<el-form-item label="收件姓名" prop="fLinkman">
						<el-input v-model="addInvoiceForm.fLinkman" />
					</el-form-item>
					<el-form-item label="联系电话" prop="fLinkPhone">
						<el-input v-model.number="addInvoiceForm.fLinkPhone" maxlength="11" />
					</el-form-item>
					<el-form-item label="邮寄地址" prop="area">
						<div class="flex-row">
							<selectArea v-model="addInvoiceForm.area"></selectArea>
						</div>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addInvoiceForm.fAddress" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="addInvoiceStatus = false">取 消</el-button>
				<el-button size="small" type="primary" @click="invoiceSubmitForm('addInvoiceForm')">确 定</el-button>
			</span>
		</el-dialog>

    <!-- 详细区域 -->
    <el-dialog
      title="定向送达活动"
      :visible.sync="isShowActivityDailog"
      width="60%">
      <el-table
        :header-cell-style="{background:'#F8F9FE'}"
        :data="activitys"
        size="mini"
        border>
        <el-table-column
          label="选择"
          align="center"
          fixed
          width="55">
          <template slot-scope="scope">
            <el-radio v-model="radioIdSelect" :label="scope.row.fRuleID" @change.native="handleSelectionChange(scope.row)" @click.native="clickRadio($event, scope.row)">{{str}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="送达目的地"
          fixed
          width="150">
          <template slot-scope="scope">
            <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="fDeliveryNames"
          label="提货方式"
          width="160">
        </el-table-column>
        <el-table-column
          align="center"
          label="运输时长(分钟)"
          width="200">
          <template slot-scope="scope">
            <span>{{scope.row.fMaxMins}}</span>
            <hr>
            <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="活动时间">
          <template slot-scope="scope">
            <span>{{scope.row.activityTime}}</span>
            <hr>
            <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="integral"
          label="赠送积分"
          width="120">
        </el-table-column>
      </el-table>
      
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isShowActivityDailog = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="详细区域"
      :visible.sync="showFence"
      class="myMap"
      width="70%">
      
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <div id="container" :style="{height: '450px', width: '100%'}"></div>
        <!-- <el-button @click="showFence = false">关 闭</el-button> -->
      </span>
    </el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	export default {
		components: {
			breadcrumb,
			selectArea
		},
		data() {
			const rulesloginId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
					if (phoneNumberRul.test(value)) {
						callback();
					} else {
						callback(new Error('请输入正确的手机号码'));
					}
				}
			}
			return {
        // 定向送达活动奖励数据
        isShowActivityDailog: false,
        str: '',
        index: -1,
        num: 0,
        fUnitNames: [],
        loadingactivity: false,
        getexpress: [],
        radioIdSelects: JSON.parse(this.$route.query.radioIdSelects),
        fDeliveryIDs: JSON.parse(this.$route.query.fDeliveryIDs),
        radioIdSelect: '',
        tableData: [],
        activitys: [],
        tableDatas: [],
        isShowExpress: false,
        showFence: false,
        denceInfo: {},
        map: {},
        district: '',
        opts: {
          subdistrict: 0, // 获取边界不需要返回下级行政区
          extensions: 'all', // 返回行政区边界坐标组等具体信息
          level: 'district' // 查询行政级别为 市
        },
        showbiz: false,
        polygonData: [],

				createLoading: false,
				isPrice:false,
				submitSuccessList: [],
				//判断议价数据是否有成功的
				submitSuccessListPrice:[],
				needPriceIndex:0,
				//承运商议价所需数组
				submitListPrice:[],
				orderLoading:false,
				laoding: false,
				fFreightPrice: 0,
				orderList: [], //订单列表
				goodsOrder: [], //获取商品价格和概要信息需要的参数
				useGoodsInformation: [], //组织需要的参数
				chooseAddress: [], //地址信息
				invoiceInformation: {}, //发票信息

				addInvoiceStatus: false,
				addInvoiceForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: ['', '', '', ''],
				},
				rules: {
					feMail: [{
						required: true,
						message: '请输入收件邮箱',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: '请输入有效的邮箱',
						trigger: 'blur'
					}],
					fLinkman: [{
						required: true,
						message: '请输入收件姓名',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([a-zA-Z0-9\u4e00-\u9fa5\·]{1,10})$/,
						message: '您的真实姓名格式错误,请输入英文或汉字!',
						trigger: 'blur'
					}],
					fLinkPhone: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'blur',
					}, {
						required: true,
						pattern: /^1[34578]\d{9}$/,
						message: '请输入11位正确手机号码',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur',
					}],
					fAddress: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur',
					}],
				},
				invoiceType: '', //发票收件信息新增还是编辑

				fIsNeedInvoiceStatus: 1, //是否开具货款发票

				mouse: -1,

				addVisible: false,
				addressList: [], //地址类型
				mapShow: false, //地图
				maplat: '',
				maplng: '',
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				pageTitle: "新增",
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: ['', '', '', ''],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: 1,
					fIsDefault: false
				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: 'blur'
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					feMail: [{
							required: true,
							message: '请填写邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请填写正确的邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}],
					fAddressTypeID: [{
						required: true,
						message: '请选择地址类型',
						trigger: 'blur'
					}],
				},

				addressStatus: false, //除了默认地址以外其他地址是否显示
				addressIndex: 0, //除了默认地址以外显示哪一个商品的其他地址

				addressTol: [], //除了默认地址以外其他地址
				active: 2,

				// 提交数组
				submitList: [],
				resultdata:[]
			};
		},
		async mounted() {
      console.log(this.$route.query.radioIdSelects)
			this.fIsNeedInvoiceStatus = this.getUserInfo.fCommerceTypeID == '1' ? 0 : 1; //fCommerceTypeID == '1'个人用户默认不开发票  fCommerceTypeID == '2'企业用户默认开发票
			//console.log('this.$route.params.obj', this.$route.params.obj);
			let data = await this.$store.getters.getCommonData;
			data.forEach((item, index) => {
				let obj = {
					id: item.fGoodsID
				};
				let obj1 = {
					index: index,
					addressId: '',
					fGovernmenAreaID: -1,
					goodsId: item.fGoodsID,
					deliveryId: item.fDeliveryID,
					fShopUnitID: item.fShopUnitID,
					fPlanAmount: item.fAmount,
					fGoodsPrice: '',
					fCapTypeID:item.fCapTypeID
				};
				this.goodsOrder.push(obj);
				this.useGoodsInformation.push(obj1);
				this.chooseAddress.push({});
			});
			this.getAddressType();
			this.getProjectList();
      console.log(this.$route.query)
		},
		// created() {
		// 	this.getAddressType();
		// 	this.getProjectList();
		// },
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//获取资金类型数据
			async acquireCapType(){
					await this.useGoodsInformation.forEach((data, index) => {
						this.ApiRequestPostNOMess('/api/mall/ebsale/b2b-inquiry-apply-goods-price/get-list-goods-capType-by-government-area-id-b2b',{
							"fGoodsID": data.goodsId,
							"fDeliveryID": data.deliveryId
						}).then(
							result => {
							if (result.obj.some(item => item.fCapTypeID == data.fCapTypeID)) {
								// 订单选中配送方式判断变量赋值
								this.orderList[index].fCapTypeID = data.fCapTypeID;
							} else {
								if(result.obj.length !=0){
									this.orderList[index].fCapTypeID = result.obj[0].fCapTypeID;
									this.useGoodsInformation[index].fCapTypeID =  result.obj[0].fCapTypeID
								}else{
									this.orderList[index].fCapTypeID = '-1'
									this.useGoodsInformation[index].fCapTypeID = '-1'
								}
							}
							this.orderList[data.index].fCapList = result.obj;
							this.getGoodsPrice();
							},
							rej => {}
						)
					})
			},
			testApiFun(item) {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						resolve(item)
					}, 1000)
				})
			},
			// 当前订单应支付金额
			getOrderPrice(item) {
				if(item.fDeliveryID ==1){
					return item.fGoodsPrice * item.fPlanAmount + item.freightAmount
				}else{
					item.freightAmount = 0
					return item.fGoodsPrice * item.fPlanAmount + item.freightAmount
				}
				
			},
			// 订单总额
			getOrderTotal() {
				let total = 0;
				this.orderList.forEach(item => {
					total = total + this.getOrderPrice(item)
				})
				return total
			},
			toEdit() {
				this.$router.push({
					name: 'FillOrderEdit',
					params: {
						id: '327164499501000001'
					}
				})
			},
			//查询地址类型
			getAddressType() {
				this.ApiRequestPost('/api/mall/ebbase/address-type/get-list', {}).then(
					result => {
						//console.log(result);
						this.addressList = result.obj.items;
					},
					rej => {}
				);
			},
			//获取地址列表
			getProjectList() {
				////console.log(this.tabindex+"this.tabindex");
				this.ApiRequestPost('api/mall/eb-customer/address/get-enable-list-by-current-user-address', {
					addressTypeId: 1, //地址类型参数
					maxResultCount: 99,
					skipCount: 0
				}).then(
					result => {
						//console.log("获取地址列表", result);
						if (result.obj.items.length > 0) {
							this.addressTol = result.obj.items;
							let defaultaddress = {};
							result.obj.items.forEach((item, index) => {
								item.fFullPathAddress = item.fFullPathAddress.replace(/\\/g, '');
							});
							let findData = result.obj.items.find(findItem => findItem.fIsDefault == 1)
							if (findData) {
								defaultaddress = findData;
							} else {
								defaultaddress = result.obj.items[0];
							}
							//console.log("defaultaddress", defaultaddress);
							this.chooseAddress.forEach((item, index) => {
								this.chooseAddress[index] = defaultaddress;
								// uni.setStorageSync('setPageData' + index, res.obj);
							});
							for (let item of this.useGoodsInformation) {
								item.addressId = this.chooseAddress[0].fAddressID;
								item.fGovernmenAreaID = this.chooseAddress[0].fGovernmenAreaID;
							}
						}
						this.getOrder();
						////console.log(this.proaddListOne,"修改后的数据");

					},
					rej => {}
				);
			},

			//获取发票信息
			getInvoiceInfo() {
				this.getInvoiceAddress();
				this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/get', {}).then(
					res => {
						// console.log("获取发票信息", res);
						if (res.obj.fApproveStatus != 254) {
							this.$message('发票未审核通过');
						}
						// this.orderList.forEach((childitem, childindex) => {
						// 	if (item.index == childindex) {
						// 		childitem.invoiceInformation.fUnitName = res.obj.fUnitName;
						// 		childitem.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						// 		childitem.invoiceInformation.fBankNames = res.obj.fBankNames;
						// 		childitem.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
						// 	}
						// });
						this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceClassName;
						this.invoiceInformation.fUnitName = res.obj.fUnitName;
						this.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						this.invoiceInformation.fAddress = res.obj.fAddress.replace(/\\/g, '');
						this.invoiceInformation.fPhone = res.obj.fPhone;
						this.invoiceInformation.fBankNames = res.obj.fBankNames;
						this.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
					},
					error => {}
				);
			},
			//获取发票地址
			getInvoiceAddress() {
				this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-invoice-address', {}).then(
					res => {
						// console.log("获取发票地址", res);
						// this.orderList.forEach((childitem, childindex) => {
						// 	if (item.index == childindex) {
						// 		// console.log('res.obj', res.obj);
						// 		childitem.invoiceInformation.feMail = res.obj.feMail;
						// 		childitem.invoiceInformation.fLinkman = res.obj.fLinkman;
						// 		childitem.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
						// 		childitem.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						// 		childitem.invoiceInformation.invoiceInformationTipsShow = false;
						// 	}
						// });
						// console.log('this.orderList', this.orderList);
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addInvoiceForm = res.obj;
						this.invoiceInformation.feMail = res.obj.feMail;
						this.invoiceInformation.fLinkman = res.obj.fLinkman;
						this.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
						this.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
					},
					error => {}
				);
			},

			//发票信息显示
			invoiceInformationTipsShowClick() {
				this.invoiceInformation.invoiceInformationTipsShow = !this.invoiceInformation.invoiceInformationTipsShow;
				this.$forceUpdate();
			},

			//获取订单
			getOrder() {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get-batch', this.goodsOrder).then(
					res => {
						//console.log("获取订单", res);
						this.orderList = [];
						this.useGoodsInformation.forEach((item, index) => {
							res.obj.forEach((childitem, childindex) => {
								childitem.address = this.chooseAddress[0];
							});
							res.obj[item.index].noDefaultAddress = this.addressTol;
							// res.obj[item.index].fPicturePath = this.getUploadFileURL(res.obj[item.index].fPicturePath);
							res.obj[item.index].deliveryMethodData = item.deliveryId; //上个页面默认配送方式
							res.obj[item.index].fDeliveryID = item.deliveryId.toString(); //上个页面默认配送方式
							res.obj[item.index].fPlanAmount = item.fPlanAmount; //买了多少件
							res.obj[item.index].fCarrierID = -1; //承运商默认-1
							res.obj[item.index].fFreightPriceTax = 0; //需要运输发票之后含税运费
							res.obj[item.index].fIsNeedFInvoiceStatus = 0; //是否需要运输发票0不需要1需要
							res.obj[item.index].transportationInvoiceDtos = [{
									title: '需要',
									id: 1
								},
								{
									title: '不需要',
									id: 0
								}
							];
							res.obj[item.index].invoiceInformation = {};
							res.obj[item.index].fBusinessTypeID = '1'; // 运输方式ID
						});

						// res.obj.forEach((item, index) => {
						// 	item.address = this.chooseAddress[0];
						// 	item.goodsImage = this.getUploadFileURL(item.goodsImage);
						// 	item.noDefaultAddress = this.addressTol;
						// 	item.deliveryDtos.forEach((childitem, childindex) => {
						// 		if (item.deliveryId == childitem.fDeliveryID) {
						// 			childitem.checked = true;
						// 			item.deliveryMethodData = childitem.fDeliveryID;
						// 		} else {
						// 			childitem.checked = false;
						// 		}
						// 	});
						// });


						this.orderList = res.obj;
						this.getBusinessType();
						// console.log("this.orderList +++++++++++++++++++++++++", this.orderList);
						this.getInvoiceInfo();
						this.getDeliveryByAreaid();
						//用户可选择的资金类型
						// this.acquireCapType()

            for (var i = 0; i < res.obj.length; i++) {
              let obj1 = {}
              obj1.FGoods = res.obj[i].fGoodsID
              obj1.FDepotID = res.obj[i].fDepotID
              console.log(this.fDeliveryIDs)
              obj1.fDeliveryIDs = this.fDeliveryIDs[i]

              this.getexpress.push(obj1)
              this.fUnitNames.push(res.obj[i].fUnitName)

            }
					},
					error => {}
				);
			},
			//获取商品价格
			async getGoodsPrice(e) {
			let xxx = []
			for (let item of this.useGoodsInformation) {
				xxx.push({
					 fGoodsID: item.goodsId,
					 fDeliveryID: item.deliveryId,
					 fCapTypeID:item.fCapTypeID
				})
			}
			// xxx.push(this.useGoodsInformation[0].goodsId)
			this.ApiRequestPost('api/mall/ebsale/b2b-inquiry-apply-goods-price/get-b2b-price-by-goods-and-deliver', xxx)
				.then(
					res => {
						console.log(res.obj)
						res.obj.forEach((item, index) => {
							this.orderList.forEach((childitem, childindex) => {
								console.log(item)
								if(!item.fGoodsMinPrice){
									console.log(item)
									for(let sonitem of item.deliverys){
										if(sonitem.fDeliveryID == childitem.fDeliveryID){
											childitem.fGoodsPrice = sonitem.fGoodsPrice
										}
									}
									if(item.deliverys.length != 0){
										item.fGoodsMinPrice = item.deliverys[0].fGoodsPrice
									}
								}
								if (item.fGoodsID == childitem.fGoodsID) {
									for(let sonitem of item.deliverys){
										if(sonitem.fDeliveryID == childitem.fDeliveryID){
											childitem.fGoodsPrice = sonitem.fGoodsPrice
										}
									}
									childitem.fGoodsMinPrice = item.fGoodsMinPrice;
										if(childitem.fGoodsPrice != childitem.fGoodsMinPrice || childitem.deliveryDtos.length == 0){
															this.$message('商品不在当前收货地址销售，请重新选择收货地址');
										}
									if(childitem.fDeliveryID == 1){
										this.isPrice = true
										childitem.fCarrierID  = -1
									}else if(childitem.fDeliveryID == 5 && childitem.carrierDtos.length >1){
										childitem.fCarrierID = childitem.carrierDtos[1].fCarrierID
										childitem.fGoodsMinPrice = Number(childitem.fGoodsMinPrice)  +  Number(childitem.carrierDtos[1].fFreightPriceTax)  + Number(childitem.carrierDtos[1].fMoney)
										childitem.fGoodsPrice =  Number(childitem.fGoodsPrice)  +  Number(childitem.carrierDtos[1].fFreightPriceTax)  + Number(childitem.carrierDtos[1].fMoney)
										childitem.fCarrierID = childitem.carrierDtos[1].fCarrierID
									}else{
										this.isPrice = false
									}
									console.log(item.deliverys,'看数据')
									if(item.deliverys.length != 0 ){
										childitem.fPointPrice = item.deliverys[0].fPointPrice; // 积分价格
										childitem.fMaxPointMoney =item.deliverys[0].fMaxPointMoney; // 商品最大可使用积分
										childitem.pointRMBPrice = item.deliverys[0].pointRMBPrice; // 积分换算率
										childitem.fMinSaleAmount = item.deliverys[0].fMinSaleAmount; // 最小销量
										childitem.fAdjustmentRange =item.deliverys[0].fAdjustmentRange; // 销量调整幅度
										childitem.fPrecision =item.deliverys[0].fPrecision; // 销量小数位数
									}
									// fIsEnableUseCoupon 0无法使用1可以使用优惠券
									// childitem.fIsEnableUseCoupon = item.fIsEnableUseCoupon;
									// childitem.fUsePoint = this.calculationEndBalance(childitem); // 用户使用积分数默认最大积分
									// this.inputBlur(childitem, childindex);
								}
							});
						});
						console.log(this.orderList,'9999999999999')
						// this.getCouponInfo(); // 获取优惠券
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
			},
			// 获取运输方式
			getBusinessType() {
				this.laoding = true;
				let goodsIDList = []
				this.orderList.forEach((item, index) => {
					goodsIDList.push({
						goodsId: item.fGoodsID
					})
				});
				this.ApiRequestPostNOMess('api/mall/ebsale/transport-business-type-rule/get-list-by-goods-id-batch', goodsIDList)
					.then(
						res => {
							console.log('运输方式', res.obj);
							res.obj.forEach((data, dataIndex) => {
								this.orderList.forEach((item, itemIndex) => {
									if (data.goodsid == item.fGoodsID) {
										// 查找汽运 并将其放置数组第一列
										let findData = data.list.find(findItem => findItem.fBusinessTypeID == "1")
										data.list.splice(data.list.indexOf(findData), 1);
										data.list.unshift(findData);
										this.orderList[itemIndex].BusinessType = data.list;
										console.log('this.orderList[itemIndex].BusinessType', this.orderList[itemIndex].BusinessType);
									}
								})
							})
							this.$forceUpdate();
							this.laoding = false;
						},
						error => {}
					);
			},
			// 选择运输方式
			BusinessTypeChoose(index) {

			},

			//根据商品ID查询当前商品提货方式
	async getDeliveryByAreaid() {
				await this.useGoodsInformation.forEach((data, index) => {
					this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-b2b-goods-current-price-deliverys', {
							goodsId: data.goodsId, // 商品ID
							fGovernmenAreaID: data.fGovernmenAreaID // 行政区域ID
						})
						.then(
							res => {
								// 判断当前接口返回配送方式是否是订单所选配送方式
								res.obj.forEach(item => {
									item.fDeliveryID = item.fDeliveryID.toString()
								})
									if (res.obj.some(item => item.fDeliveryID == data.deliveryId)) {
										// 订单选中配送方式判断变量赋值
										this.orderList[index].deliveryMethodData = data.deliveryId.toString();
										this.orderList[index].deliveryMethodDataStatus = Number(data.deliveryId.toString())
										this.orderList[index].fDeliveryID = data.deliveryId.toString();
										this.orderList[index].freightAmount = 0;
									} else {
										if(res.obj.length !=0){
											this.orderList[index].deliveryMethodData = res.obj[0].fDeliveryID;
											this.orderList[index].deliveryMethodDataStatus = Number(res.obj[0].fDeliveryID) 
											this.orderList[index].fDeliveryID = res.obj[0].fDeliveryID;
											this.orderList[index].freightAmount = 0;
											this.useGoodsInformation[index].deliveryId =  res.obj[0].fDeliveryID
										}else{
											this.orderList[index].deliveryMethodData = ""
											this.orderList[index].deliveryMethodDataStatus = ''
											this.orderList[index].freightAmount = 0
											this.orderList[index].fDeliveryID = '-1'
											this.useGoodsInformation[index].deliveryId = '-1'
										}
											
									}
								
								// res.obj.unshift({
								// 	fDeliveryID: "-1",
								// 	fDelivery: '请选择配送方式'
								// });
								this.orderList[data.index].deliveryDtos = res.obj;
                console.log('item', this.orderList)
								this.getexpress[data.index].fDeliveryIDs = this.orderList[data.index].fDeliveryID
                // this.getexpressInfo()
								this.acquireCapType();
								console.log(this.orderList[data.index].deliveryDtos,'this.orderList[data.index].deliveryDtos')
								// this.getGoodsPrice() 因为  资金类型会再次调用  商品价格接口所以注释这行冗余代码
								this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							},
							error => {}
						);
				});
				this.getFreightPrice(); //承运商数据 需要地址ID
			},
			//资金类型点击选中事件
			capTypeIDClick(cpid,index){
				console.log(cpid,'cpid')
				console.log(index,'index')
				this.orderList[index].fCapTypeID = cpid;
				this.useGoodsInformation[index].fCapTypeID = cpid;
				this.getGoodsPrice();
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//配送方式点击事件
			deliveryMethodClick(obj, index) {
				this.orderList[index].fDeliveryID = obj;
        this.getexpress[index].fDeliveryIDs = this.orderList[index].fDeliveryID
        this.radioIdSelect = -1
        // this.getexpressInfo()
				this.useGoodsInformation[index].deliveryId = obj;
				for(let item of this.orderList){
					if(item.fDeliveryID == 1){
						this.isPrice = true
					}else if(item.fDeliveryID == 5){
						console.log(this.orderList,'查看数组的数据')
						this.isPrice = false
					}
					else{
						this.isPrice = false
					}
				}
				this.getFreightPrice(); //承运商数据
				this.orderList[index].deliveryMethodData = obj.deliveryMethodData;
				this.orderList[index].fIsNeedFInvoiceStatus = 0;
				this.orderList[index].fCarrierID = -1;
				this.orderList[index].deliveryDtos.forEach((item, index) => {
					if (item.fDeliveryID == obj.deliveryMethodData) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				})
				// this.getGoodsPrice();//底下acquireCapType()获取资金类型数据，会再次调取getGoodsPrice()方法
				this.acquireCapType();
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢

			},

		//配送方式为承运商代送时候承运商数据
		async getFreightPrice() {
			let apiData = []
			await this.useGoodsInformation.forEach((item, index) => {
				console.log('item2222', item);
				apiData.push({
					fGovernmenAreaID: item.fGovernmenAreaID,
					fGoodsID: item.goodsId
				})
			});
			await this.ApiRequestPostNOMess('api/mall/ebsale/freight-price/get-b2c-freight-price-by-gov-and-goods-id-batch',
					apiData)
				.then(
					res => {
						res.obj.forEach((data, dataIndex) => {
							for (let item of this.orderList) {
								// 判断接口返回承运商数据是否是订单所选承运商
								if (data.fGoodsID == item.fGoodsID) {
									let carrierDtosData = {
										fCarrierID: -1,
										fCustomerName: '请选择承运商'
									}
									if (data.freightPrice.length == 1 && data.freightPrice[0].fCarrierID == '0') {
										data.freightPrice = [carrierDtosData];
										item.carrierData = -1;
										item.fCarrierID = -1;
										item.carrierDtos = data.freightPrice;
										return;
									}
									item.carrierData = -1;
									item.fCarrierID = -1;
									item.fFreightPriceID = data.freightPrice[0].fFreightPriceID
									item.carrierDtos = data.freightPrice;
									item.carrierDtos.unshift(carrierDtosData);
									break;
								}
							}
						});
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
		},

			//承运商点击事件
			carrierClick(fCarrierID, index) {
				this.fFreightPriceTax = 0;
				let fMinAmount = 0;
				let fFreightPriceTax = 0;
				this.orderList[index].carrierDtos.forEach((item, index) => {
					// 选择承运商 拿取最小运载量 运输价格
					if (fCarrierID == item.fCarrierID) {
						fMinAmount = item.fMinAmount;
						if(item.fFreightPriceTax){
							fFreightPriceTax = item.fFreightPriceTax;
						}
					}
				});
				this.fFreightPriceTax = fFreightPriceTax;
				if (this.orderList[index].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount);
					this.orderList[index].carrierData = -1;
					this.orderList[index].freightAmount = 0;
					this.$forceUpdate();
					return;
				}
				this.orderList[index].freightAmount = fFreightPriceTax * this.orderList[index].fPlanAmount;
				this.orderList[index].fCarrierID = fCarrierID;
				this.orderList[index].carrierData = fCarrierID;
				this.orderList[index].carrierDtos.forEach((item, index1) => {
					if (item.fCarrierID == fCarrierID) {
						this.orderList[index].fFreightPriceTax = item.fFreightPriceTax;
						// this.orderList[index].fFreightPriceTax = item.fFreightPriceTax;
						this.orderList[index].fIsNeedFInvoiceStatus = 0;
					}
				})
				

				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//是否需要运输发票点击事件
			transportationInvoiceClick(obj, index) {
				// console.log('是否需要运输发票obj', obj);
				if (this.orderList[index].fCarrierID == -1) {
					this.orderList[index].fIsNeedFInvoiceStatus = 0;
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					this.$message('请选择承运商');
				} else {
					this.orderList[index].fIsNeedFInvoiceStatus = obj;
					if (obj == 1) {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[index].fPlanAmount;
					} else {
						this.orderList[index].freightAmount = this.fFreightPriceTax * this.orderList[index].fPlanAmount;
					}

					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				}
			},

			addInvoiceClose() {
				this.addInvoiceStatus = false;
				this.addInvoiceForm = {};
			},
			addInvoice(type) {
				this.invoiceType = type;
				if (type == 'Edit') {
					this.getInvoiceAddress();
				}
				this.addInvoiceStatus = true;
			},
			DeleteAddress(item, index) {
				for (var i = 0; i < this.addressTol.length; i++) {
					if (i == index) {
						this.addressTol.splice(index, index);
					}
				}
			},
			DeleteAddressChecked() {
				// this.address = {};
			},
			//展开关闭其他地址
			noDefaultAddressShow(index) {
				this.addressIndex = index;
				this.addressStatus = !this.addressStatus;
			},
			//数量加减
			valChange(orderData, i) {
				console.log(orderData)
				if (!orderData.fPlanAmount) {
					this.orderList[i].fPlanAmount = 0
				}
				// if (orderData.fPlanAmount % orderData.fAdjustmentRange != 0 && orderData.fAdjustmentRange != 0) {
				// 	this.orderList[i].fPlanAmount = (orderData.fPlanAmount / orderData.fAdjustmentRange).toFixed(0) * orderData.fAdjustmentRange;
				// }
				// console.log('数量加减orderData', orderData);
				if (this.orderList[i].carrierDtos) {
					let fMinAmount = 0;
					this.orderList[i].carrierDtos.forEach((item, index) => {
							console.log(orderData.carrierData,item.fCarrierID)
						if (orderData.carrierData == item.fCarrierID && item.fCarrierID != -1) {
							fMinAmount = item.fMinAmount;
							if (orderData.fIsNeedFInvoiceStatus == 0) {
								this.orderList[i].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
							} else {
								this.orderList[i].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
							}
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						}
					});
					// console.log('fFreightPrice', fFreightPrice);
						if (this.orderList[i].fPlanAmount < fMinAmount && this.orderList[index].fDeliveryID != 5) {
							this.$message('当前承运商最小运输数量为' + fMinAmount + ',请重新选择承运商');
							this.orderList[i].carrierData = -1;
							this.orderList[i].fCarrierID = -1;
							this.orderList[i].freightAmount = 0;
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							return;
						}
				}
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			// 商品数量进步器 光标消失时调用
			valBlur(orderData, index) {
				// if (orderData.fPlanAmount == 0) {
				// 	return;
				// }
				// // 最小销量：fMinSaleAmount 销量调整幅度：fAdjustmentRange 销量小数位数：fPrecision
				// // 是否整除 isNumDivisible 
				// let isNumDivisible = orderData.fPlanAmount % orderData.fAdjustmentRange == 0 ? true : false
				// if (isNumDivisible == false) {
				// 	// 除后的值 valueAfterDivision    输入量 除以 调整幅度
				// 	let valueAfterDivision = (orderData.fPlanAmount * 100 / orderData.fAdjustmentRange * 100) / 100 / 100
				// 	// 更正后的值  除后的值四舍五入 乘以 调整幅度
				// 	this.orderList[index].fPlanAmount = valueAfterDivision.toFixed(0) * orderData.fAdjustmentRange
				// }
			},

			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},

			// 选中某个复选框时，由checkbox时触发
			checkboxChange(e) {},
			// 选中任一checkbox时，由checkbox-group触发
			checkboxGroupChange(e) {},
			gotoOrder() {},
			//选择其他地址
			addressChoose(obj, activeindex) {
				// console.log('obj', obj);
				// //console.log('activeindex', activeindex);
				this.orderList[activeindex].address = obj;
				this.orderList[activeindex].addressId = obj.fAddressID;
				this.orderList[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.useGoodsInformation[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				for (let item of this.useGoodsInformation) {
					if (activeindex == item.index) {
						item.addressId = obj.fAddressID;
						item.fGovernmenAreaID = obj.fGovernmenAreaID;
					}
				}
				this.chooseAddress.forEach((item, index) => {
					this.chooseAddress[activeindex] = obj;
				});
				this.orderList.forEach((item, index) => {
					if (activeindex == index) {
						item.address = obj;
						item.addressId = obj.fAddressID;
						item.fGovernmenAreaID = obj.fGovernmenAreaID;
					}
				});


				let isSaleData = {
					addressId: this.orderList[activeindex].addressId,
					goodsId: this.orderList[activeindex].fGoodsID,
					deliveryId: this.useGoodsInformation[activeindex].deliveryId
				};

				//console.log('isSaleData', isSaleData);
				// this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-current-price-by-address-id',
				// 		isSaleData)
				// 	.then(
				// 		res => {
				// 			//console.log("判断商品是否在销售", res);
				// 			if (res.obj.length <= 0) {
				// 				this.$message('当前地址下商品不支持销售,请重新选择地址！');
				// 			} else {
				// 				res.obj.forEach((item1, index1) => {
				// 					if (item1.fIsEnableSale == 1) {
				// 						this.orderList.forEach((childitem, childindex) => {
				// 							if (item1.index == childindex) {
				// 								childitem.fGoodsPrice = item1.fGoodsPrice;
				// 								childitem.fPointPrice = item1.fPointPrice;
				// 							}
				// 						});
				// 						this.getGoodsPrice();
				// 						this.getDeliveryByAreaid()
				// 					} else {
				// 						this.$message('当前地址下商品不支持销售,请重新选择地址！');
				// 					}
				// 				});
				// 			}
				// 		},
				// 		error => {}
				// 	);
				this.getDeliveryByAreaid()
				this.acquireCapType();
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				
			},
			//议价的询问验证
			talkAboutPrice(){
				this.$confirm('是否确认该笔订单与承运商议价?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.speackPrice()
				        }).catch(() => {       
				        });
			},
			//承运商议价
			asktalkAboutPrice(){
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				for (let item of this.orderList) {
					
									if (item.deliveryDtos.length >0) {
										for (let item1 of item.deliveryDtos) {
											if (item1.fDeliveryID == -1) {
												this.$message('商品不在当前收货地址销售，请重新选择收货地址');
												return;
											}
										}
									} else {
										this.$message('商品不在当前收货地址销售，请重新选择收货地址');
										return;
									}
									console.log(item)
									if(item.fDeliveryID != 1){
										this.$message('订单中存在不可以议价的订单，请确认订单详细情况');
										return;
									}
				
								}
				if (findData) {
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.fCarrierID == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				if (this.orderList.some(p => p.fCapTypeID == -1)) {
					this.$message('请选择资金类型');
					return;
				}
				var capItem =  this.orderList.every((item1)=>{
					return  item1.fCapTypeID == this.orderList[0].fCapTypeID
				})
				if(capItem == false){
					this.$message('请确认各个订单的资金类型保持一致！');
					return;
				}
				// 提交参数
				this.submitListPrice = [];
				// 提交成功数组
				this.submitSuccessListPrice = [];
				this.orderList.forEach((item, index) => {
					if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID == '暂无可用') {
						item.fCouponCustomerReceiveRecordID = -1;
					}
					console.log(item,'555555')
					let params = {
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						fCapTypeID:item.fCapTypeID,//资金类型id
						// fUsePoint: item.fUsePoint,
						fDeliveryID: item.fDeliveryID, // 配送方式ID
						fCarrierID: item.fCarrierID, // 承运商ID
						// fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 : item.fCouponCustomerReceiveRecordID, // 优惠券ID
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
						fPlanAmount: item.fPlanAmount, // 商品数量
						fAppTypeID: this.fAppTypeID, // 应用类型ID
						fBusinessTypeID: item.fBusinessTypeID ,// 运输方式ID
						// fcFreightPrice: item.fFreightPriceTax,
						// fFreightPriceID: item.fFreightPriceID,
						//=========================
					}
					this.submitListPrice.push(params);
				});
				this.talkAboutPrice();
			
			},
			//调用承运商议价的接口
			async speackPrice() {
				let forlength = this.submitListPrice.length;
				let forIndex = JSON.parse(JSON.stringify(this.needPriceIndex))
				this.orderLoading = true
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitListPrice[index]
					// 异步调用API方法，以达到对成功的订单分段控制
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-barganining-b2b-order-new', item)
						.then(
							res => {
								console.log(res)
                if (res.obj !== null) {
                  this.needPriceIndex = index + 1;
                  this.resultdata.push(res.obj)
                  if (this.needPriceIndex == this.submitListPrice.length) {
                    var colleteList = []
                    for(let item of this.resultdata){
                      colleteList.push({
                        fOrderNumber:item.fOrderNumber,
                        fCarrierName:item.fCarrierName,
                        fCarrierPhone:item.fCarrierPhone,
                      })
                    }
                    console.log(colleteList)
                    // 跳转到议价单页面
                    this.goToUnderReview(colleteList);
                  }
								} else {
                  let str = res.message
                  let arr = str.split(';')
                  console.log(arr)
                  if (arr[1] === '001' || arr[1] === '002' || arr[1] === '003') {
                    this.$confirm(createMessage + ', 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      if (arr[1] == '001') {
                        this.$store.dispatch("setShowAttestation", true);
                      } else if (arr[1] == '002') {
                        this.addInvoice('Add');
                      } else if (arr[1] == '003') {
                        if (this.getUserInfo.fCommerceTypeID == '1') {
                          this.$router.push({
                            path: '/businessme/individualUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转个人用户绑卡
                        } else if (this.getUserInfo.fCommerceTypeID == '2') {
                          this.$router.push({
                            path: '/businessme/enterpriseUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转企业用户绑卡
                        }
                      }
                    })
                  } else {
                    this.$message(arr[0])
                  }
                  // if (arr[arr.length-1] === '001')
                  this.orderLoading = false
                }
								
								
							},
							error => {
                this.$message.error(error)
                this.orderLoading = false
							}
						);
				}
			},
			//跳转审核中页面
			goToUnderReview(val) {
				console.log('议价审核中');
				// this.$router.push("/about/Allperfect");
				this.orderLoading = false
				this.$router.push({
					name: "bargainPrice",
					params:{fname: JSON.stringify(val)},
				});
			},
			//结算创建订单
			createOrder() {
				if(this.getOrderTotal() <0){
					this.$message('亲，您的订单金额有问题，请联系商城客服!');
					return
				}
				var falg = false;
					this.orderList.forEach((val,index)=>{
						if (val.fStatus == 2) {
							falg = true;
						}
					});
					if(falg == true){
						 this.$message('亲，当前商品已售罄，无法购买');
						 return
					}
					// var falgcontinue = false;
					// this.orderList.forEach((val,index)=>{
					// 	if (val.fGoodsPrice != val.fGoodsMinPrice) {
					// 		falgcontinue = true;
					// 	}
					// });
					// if(falgcontinue == true){
					// 	 this.$message('商品不在当前收货地址销售，请重新选择收货地址！');
					// 	 return
					// }
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				if (findData) {
					// this.$message('请维护地址或者选择地址！');
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.fCarrierID == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fCapTypeID == -1)) {
					this.$message('请选择资金类型');
					return;
				}
				var capItem =  this.orderList.every((item1)=>{
					return  item1.fCapTypeID == this.orderList[0].fCapTypeID
				})
				if(capItem == false){
					this.$message('请确认各个订单的资金类型保持一致！');
					return;
				}
				// 提交参数
				this.submitList = [];
				// 提交成功数组
				this.submitSuccessList = [];
				this.orderList.forEach((item, index) => {
					console.log('item', item);
					let params = {
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						fDeliveryID: item.fDeliveryID,
						fCarrierID: item.fCarrierID,
						fCapTypeID:item.fCapTypeID,//资金类型id
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus,
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus,
						fPlanAmount: item.fPlanAmount,
						fAppTypeID: this.fAppTypeID,
						fBusinessTypeID: item.fBusinessTypeID
					}
					this.submitList.push(params);
				});
				console.log('this.submitList', this.submitList);
				this.submitOrderList();
			},
			// 异步【递归】提交订单方法
			async submitOrderList() {
				console.log(this.submitList,'看下提交订单时的配送方式id')
				for (let [index, item] of this.submitList.entries()) { // for ... of...es6新增循环方法但是无法获取下标需借助entries()方法来获取
					// 异步调用API方法，以达到对成功的订单分段控制
          // if (this.tableData[index].length === 0) {
          //     item.FRuleID = -1
          //   } else {
          //     item.FRuleID = this.tableData[index][0].fRuleID
          //   }
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-b2b-order-new', item)
						.then(
							res => {
								console.log('提交订单方法', res);
								if (res.message == "") {
									this.submitSuccessList.push(res.obj);
									this.creatPayList();
								} else {
									// isSuccsss = false;
									// 如果失败则提示用户是否继续
									res.message = res.message.split(';');
									let createTipCode = res.message[1];
									let createMessage = res.message[0];
									console.log('createTipCode', createTipCode);
									this.$confirm(createMessage + ', 是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {
										if (createTipCode == '001') {
											this.$store.dispatch("setShowAttestation", true);
										} else if (createTipCode == '002') {
											this.addInvoice('Add');
										} else if (createTipCode == '003') {
											if (this.getUserInfo.fCommerceTypeID == '1') {
												this.$router.push({
													path: '/businessme/individualUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转个人用户绑卡
											} else if (this.getUserInfo.fCommerceTypeID == '2') {
												this.$router.push({
													path: '/businessme/enterpriseUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转企业用户绑卡
											}
										} else if (createTipCode == '004') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										} else if (createTipCode == '005') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										} else if (createTipCode == '006') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										}
									}).catch(() => {
										// 取消则重置记录数据
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									});
								}
							},
							error => {}
						);
				}
			},
			// 跳转支付单
			async creatPayList() {
				console.log("跳转支付单");
				// 组织订单ID数组
				let orderIds = []
				let fDeliveryIDList = []
				await this.submitSuccessList.forEach(item => {
					console.log('item', item);
					orderIds.push(item.fOrderID.toString())
					fDeliveryIDList.push(item.fDeliveryID)
				})
				console.log("orderIds", JSON.stringify(orderIds));
				sessionStorage.setItem('fDeliveryID', this.orderList[0].fDeliveryID);
				// 跳转  replace :此方法跳转不会记录当前页面。
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						orderIds: orderIds,
						fBillTypeID: this.orderList[0].fBillTypeID,
						fDeliveryIDList:fDeliveryIDList
					}
				})
			},

			//获取当前位置信息
			getMyAddress() {
				let _this = this;
				this.mapShow = true;

				this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=' + this.addr +
						'&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&output=jsonp', {})
					.then(json => {
						// alert(JSON.stringify(json.result.location));
						_this.maplat = json.result.location.lat;
						_this.maplng = json.result.location.lng;
						// 初始化地图,这里的1秒延迟是必要的,否则不能正常渲染出来 
						setTimeout(function() {
							_this.initMap()
						}, 1000);

					})
					.catch(err => {
						alert(JSON.stringify(err));
					});
			},
			//初始化地图
			initMap() {
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				// "lat": 43.794399第一个参数
				// "lng": 87.631676,第二个参数
				var center = new TMap.LatLng(this.maplat, this.maplng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map(container, {
					center: center
				});
				/* 这个就是那个地图上的指针点 */
				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						myStyle: new TMap.MarkerStyle({
							width: 25, // 点标记样式宽度（像素）
							height: 35, // 点标记样式高度（像素）
							//src: '../img/marker.png', //自定义图片路径
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							anchor: {
								x: 16,
								y: 32
							}
						})
					},
					//点标记数据数组
					geometries: [{
						id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
						styleId: 'myStyle', //指定样式id
						position: new TMap.LatLng(this.maplat, this.maplng), //点标记坐标位置
						properties: {
							//自定义属性
							title: 'marker1'
						}
					}]
				});
			},
			//新增地址
			addAddress() {
				this.pageTitle = "新增";
				this.addVisible = true;
			},
			//编辑地址
			EditAddress(item) {
				this.pageTitle = "编辑";
				console.log('item', item);
				this.ApiRequestPostNOMess("api/mall/eb-customer/address/get", {
						id: item.fAddressID
					})
					.then(res => {
						console.log('编辑地址', res);
						res.obj.fIsDefault = res.obj.fIsDefault == 0 ? false : true
						res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addressForm = res.obj;
						console.log('this.addressForm', this.addressForm);
						this.addVisible = true;
					}, error => {});
			},
			//新增或者编辑地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
						if (this.pageTitle == "编辑") {
							console.log('this.addressForm', this.addressForm);
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.rowStatus = 16;
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									// console.log(result);
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						} else if (this.pageTitle == "新增") {
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: 1,
								fCustomerID: 0,
								feMail: "",
								fGovernmenAreaID: this.addressForm.fGovernmenAreaID,
								fAddress: this.addressForm.fAddress,
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1,
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0,
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
							}).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						}
					} else {
						return false;
					}
				});
			},
			handleClose() {
				this.addVisible = false;
				this.resetForm();
			},
			//重置form表单
			resetForm() {
				this.addressForm = {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: 1,
					fIsDefault: false
				}
			},

			//保存收件信息
			invoiceSubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.invoiceType == 'Add') {
							this.addInvoiceForm.fAddressID = 0;
						}
						/* this.addInvoiceForm.fGovernmenAreaID = this.addInvoiceForm.area[this.addInvoiceForm.area.length-1];
						console.log(this.addInvoiceForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							fAddressID: this.addInvoiceForm.fAddressID,
							feMail: this.addInvoiceForm.feMail,
							fGovernmenAreaID: this.addInvoiceForm.area[this.addInvoiceForm.area.length - 1],
							fAddress: this.addInvoiceForm.fAddress,
							fLinkman: this.addInvoiceForm.fLinkman,
							fLinkPhone: this.addInvoiceForm.fLinkPhone
						}).then(
							result => {

								console.log('已经修改完成！！')
								this.addInvoiceStatus = false;
								console.log(result);
								this.getInvoiceInfo();
							},
							rej => {}
						);
					} else {
						return false;
					}
				});
			},


      // 显示地图
    handleClick(row) {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        center: [87.616842, 43.82539], // [87.616842, 43.82539], //初始地图中心点
        zoom:12, //初始地图级别
        animateEnable: true
      });
      this.district = new AMap.DistrictSearch(this.opts, this.showbiz)
      this.showFence = true
      this.ApiRequestPostNOMess('api/mall/ebmonitor/sale-area-fence/get-entity-and-detaillist', {id: row.fFenceID}).then(res => {
        this.polygonData[0] = res.obj
          this.initMyMap()
      })
      
    },
    // 初始化地图
    initMyMap() { 
      let center
      if (this.polygonData[0].fFenceTypeID === 1) {
        center= [this.polygonData[0].fDetail[0].flng, this.polygonData[0].fDetail[0].flat]
        const circle = new AMap.Circle({
          center: center,
          radius: this.polygonData[0].fDetail[0].fRadius, //半径
          trokeWeight: 1,
          fillOpacity: 0.4,
          fillColor: '#80d8ff',
          strokeColor: 'red',
          extData: {
            item: this.polygonData[0]
          }
        })
        this.map.add(circle)
        this.map.setFitView(circle)
        this.map.setFitView()
        const textInfo = new AMap.Text({
          text: `${this.polygonData[0].fFence}(编号${this.polygonData[0].fFenceCode})`,
          style: { 'background-color': 'rgba(255,255,255,.5)', 'color': '#0091ea' },
          position: [this.polygonData[0].fDetail[0].flng, this.polygonData[0].fDetail[0].flat],
          extData: {
            fFenceID: this.polygonData[0].fFenceID,
          }
        })
        this.map.add(textInfo)
      } else {
        if (this.polygonData[0].fIsUseCity === 1) {
          const adcode = this.polygonData[0].fGovernmenAreaCode
          const that = this
          // that.district.search(adcode, function(status, result) {
          that.district.search(adcode, (status, result) => {
            // let path = result.districtList[0].boundaries
            var bounds = result.districtList[0].boundaries;
            let path1 = bounds[0]
            for (var i = 0; i < bounds.length; i++) {
              if (bounds[i].length > path1.length) {
                path1 = bounds[i]
              }
            }
            if (bounds) {
              //生成行政区划polygon
              for(var i=0;i<bounds.length;i+=1){//构造MultiPolygon的path
                bounds[i] = [bounds[i]]
              }
              let polygon = new AMap.Polygon({
                strokeWeight: 1,
                path: bounds,
                fillOpacity: 0.4,
                fillColor: '#80d8ff',
                strokeColor: 'red',
              });
              this.map.add(polygon)
              this.map.setFitView(polygon);//视口自适应
            }
            const arr = []
            for (var j = 0; j < path1.length; j++) {
              const obj = []
              obj.push(path1[j].lng)
              obj.push(path1[j].lat)
              arr.push(obj)
            }
            const center = this.calculateCenter(arr)
            const textInfo = new AMap.Text({
              text: `${this.polygonData[0].fFence}(编号${this.polygonData[0].fFenceCode})`,
              style: { 'background-color': 'rgba(255,255,255,.5)', 'color': 'red' },
              position: center,
            })
            this.map.add(textInfo)
          })
        } else {
          let arr = []
          let path = []
          arr = this.polygonData[0].fDetail
          arr.forEach(item => {
            let obj = []
            obj.push(item.flng)
            obj.push(item.flat)
            path.push(obj)
          })
          center = this.calculateCenter(path)
          const polygon = new AMap.Polygon({
            trokeWeight: 1,
            path: path,
            fillOpacity: 0.4,
            fillColor: '#80d8ff',
            strokeColor: 'red',
            extData: {
              item: this.polygonData[0]
            }
          })
          this.map.add(polygon)
          this.map.setFitView(polygon)
          const textInfo = new AMap.Text({
            text: `${this.polygonData[0].fFence}(编号${this.polygonData[0].fFenceCode})`,
            style: { 'background-color': 'rgba(255,255,255,.5)', 'color': '#0091ea' },
            position: center,
            extData: {
              fFenceID: this.polygonData[0].fFenceID,
            }
          })
          this.map.add(textInfo)
        }
      }
    },
    // 获取多变行中心坐标
    calculateCenter(lnglatarr) {
      var total = lnglatarr.length;
      var X = 0
      var Y = 0
      var Z = 0
      lnglatarr.map((item) => {
        var lng = (item[0] * Math.PI) / 180;
        var lat = (item[1] * Math.PI) / 180;
        var x, y, z;
        x = Math.cos(lat) * Math.cos(lng);
        y = Math.cos(lat) * Math.sin(lng);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
      });
      X = X / total;
      Y = Y / total;
      Z = Z / total;
      var Lng = Math.atan2(Y, X);
      var Hyp = Math.sqrt(X * X + Y * Y);
      var Lat = Math.atan2(Z, Hyp);
      return ([(Lng * 180) / Math.PI, (Lat * 180) / Math.PI]);
    },
    // 获取限时奖励信息
    getexpressInfo() {
      this.isShowExpress = false
      for (let i = 0; i < this.getexpress.length; i++) {
        this.ApiRequestPostNOMess('api/mall/ebactivity/limited-delivery-rule/get-limited-delivery-rule-B2B', this.getexpress[i]).then(res => {
          if (res.length == 0) {
            this.isShowExpress = false 
            this.tableData[i] = [
						{
							activityTime: '',
							fDeliveryNames: '',
							fMaxMins: '',
							fFence: '',
							activeActivity: ''
						},
						
					]
            return
          } else {
            this.isShowExpress = true
            this.loadingactivity = true
            this.tableDatas[i] = res
            console.log(this.tableDatas[i], this.radioIdSelects)

            const arr = this.tableDatas[i].filter(item => item.fRuleID == this.radioIdSelects[i])
            this.tableDatas[i].forEach(item => {
              item.activityTime = this.getTime(item.fBeginTime)   + '至' + this.getTime(item.fEndTime) 
              item.integral = item.fPointAmount + '积分/' + this.fUnitNames[i]
            })
            this.tableData[i] = arr
            this.loadingactivity = false
          }
        })
      }
    },
    showActivity(index) {
      this.index = index
      if (this.num === 0) {
        this.radioIdSelect = this.radioIdSelects[index]
      }
      this.num++
      this.activitys = this.tableDatas[index]
      this.isShowActivityDailog = true
    },
    handleSelectionChange(val) {
      this.tableData[this.index] = [val]
    },
    clickRadio(e, row) {
        if(e.target.tagName != 'INPUT'){
          return
      }
        if (this.radioIdSelect === row.fRuleID) {
          this.radioIdSelect = -1
          this.tableData[this.index] = []
          this.index = -1
        }
    },
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;
		// transition: all 0.5s ease;
		// &::after {
		// 	content: '✔';
		// 	display: block;
		// 	height: 0px;
		// 	width: 0px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	right: 0;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	line-height: 18px;
		// 	border: 18px solid;
		// 	// border-color: transparent #55aaff #55aaff transparent;
		// 	@include themify($themes) {
		// 		border-color: transparent themed('themes_color') themed('themes_color') transparent;
		// 	}
		// }
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-top-F2F2F2 .el-input__inner {
		border-radius: 0;
		width: 196px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 196px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-top: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		padding: 10px 20px 20px 20px;
	}
	.form-label-mark {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #ccc;
		cursor: pointer;
		margin-right: 10px;
	}

  .myMap {
    .el-dialog__body {
      padding: 0;
      
    }
  }
  .btn {
    float: right;
    color: #0173FE;
    cursor: pointer;
    margin-right: 15px;
  }
</style>
